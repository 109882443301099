html,
body {
  width: 100%;
  height: 100%;
}
body {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Google sans", sans-serif;
  font-size: 1.2rem;
  overflow: hidden;
  //   media queries for mobile portrait
  @media only screen and (max-device-width: 600px) {
    font-size: 0.9rem;
  }

  color: rgba(0, 0, 0, 0.6);
  .footer {
    position: absolute;
    z-index: 2;
    bottom: 0;
    padding: 40px;
    margin: 10px;
    // background-color: #535f60;
    // border-radius: 8px;
    @media only screen and (max-device-width: 600px) {
      padding: 5px;
    }
    @media only screen and (max-device-width: 850px) and (orientation: landscape) {
      display: none;
    }
    span {
      display: block;
      &.inline {
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
        &:hover {
          color: yellow;
        }
        @media only screen and (max-device-width: 600px) {
          font-size: 1.2rem;
          margin-left: 20px;
          &:hover {
            color: unset;
          }
        }
      }
    }
    p {
      margin: 0px;
      margin-top: 20px;
      padding: 0px;
      display: flex;
      @media only screen and (max-device-width: 600px) and (orientation: landscape) {
        margin-top: 0px;
      }
    }
  }
}
