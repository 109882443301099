html, body {
  width: 100%;
  height: 100%;
}

body {
  color: #0009;
  margin: 0;
  padding: 0;
  font-family: Google sans, sans-serif;
  font-size: 1.2rem;
  overflow: hidden;
}

@media only screen and (device-width <= 600px) {
  body {
    font-size: .9rem;
  }
}

body .footer {
  z-index: 2;
  margin: 10px;
  padding: 40px;
  position: absolute;
  bottom: 0;
}

@media only screen and (device-width <= 600px) {
  body .footer {
    padding: 5px;
  }
}

@media only screen and (device-width <= 850px) and (orientation: landscape) {
  body .footer {
    display: none;
  }
}

body .footer span {
  display: block;
}

body .footer span.inline {
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
}

body .footer span.inline:hover {
  color: #ff0;
}

@media only screen and (device-width <= 600px) {
  body .footer span.inline {
    margin-left: 20px;
    font-size: 1.2rem;
  }

  body .footer span.inline:hover {
    color: unset;
  }
}

body .footer p {
  margin: 20px 0 0;
  padding: 0;
  display: flex;
}

@media only screen and (device-width <= 600px) and (orientation: landscape) {
  body .footer p {
    margin-top: 0;
  }
}

/*# sourceMappingURL=index.5ba4a165.css.map */
